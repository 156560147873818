<script>
	export let cls = ''
	export let height = null
	export let type = 'primary'
</script>

<section class="loading {cls} {type}" style:height>
	<div />
	<div />
	<div />
	<div />
</section>

<style lang="scss">
	@keyframes primary {
		0% {
			transform: scale(0.5);
			background: #ffffff;
		}
		50% {
			transform: scale(1);
			background: #eeeeee;
		}
		100% {
			transform: scale(0.5);
			background: #ffffff;
		}
	}

	@keyframes secondary {
		0% {
			transform: scale(0.5);
			background: #a50000;
		}
		50% {
			transform: scale(1);
			background: #e20613;
		}
		100% {
			transform: scale(0.5);
			background: #a50000;
		}
	}

	.loading {
		display: flex;
		gap: 5px;
		height: 24px;
		align-items: center;

		&.primary div::after {
			animation: primary 1.5s infinite ease-out;
		}

		&.secondary div::after {
			animation: secondary 1.5s infinite ease-out;
		}

		div {
			position: relative;
			width: 11px;
			height: 11px;
			border-radius: 999px;
			box-sizing: border-box;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 11px;
				height: 11px;
				transform-origin: center;
				border-radius: 999px;
			}

			&:nth-child(2)::after {
				animation-delay: 0.1s;
			}

			&:nth-child(3)::after {
				animation-delay: 0.2s;
			}

			&:nth-child(4)::after {
				animation-delay: 0.3s;
			}
		}
	}
</style>
